import React, { HTMLAttributes } from "react";

interface IPrivacy {}

type TPrivacyProps = IPrivacy & HTMLAttributes<HTMLDivElement>;

const Privacy: React.FC<TPrivacyProps> = (props) => {
  const { ...otherProps } = props;

  return (
    <div
      className="flex flex-1 flex-col min-h-[100vh] h-full text-white"
      {...otherProps}
    >
      <div className="flex justify-between items-center m-6 laptop:m-10 z-10">
        {/* left top logo */}
        <img
          className="w-[8rem] h-[3rem]"
          src={require("../../../assets/images/asgard-ev-logo.png")}
          alt="asgard-ev-logo"
        />

        {/* right top logo */}
        <img
          className="w-[120px] h-[32px]"
          src={require("../../../assets/images/asgard-ev-logo2.png")}
          alt="asgard-ev-logo2"
        />
      </div>
      <div className="h-full overflow-auto flex flex-col text-left bg-bgDark p-10 text-sm">
        <span className="font-extrabold text-lg mb-2">
          Welcome to the Asgard Group Limited Website ("Website"). At Asgard
          Group
        </span>

        <span>
          At Asgard Group Limited ("Asgard," "we," "us," or "our"), your privacy
          is important to us. This Privacy Policy outlines how we collect, use,
          disclose, and safeguard your personal information when you engage with
          our Website or services. We adhere to the Personal Data (Privacy)
          Ordinance (PDPO) (Chapter 486) of the Laws of Hong Kong, as well as
          other relevant privacy regulations.
        </span>
        <br />

        <span className="font-extrabold text-lg mb-2">
          When you use our Website, we may collect and process the following
          types of personal data:
        </span>

        <span>
          Contact Details: Name, email address, phone number, country of
          residence, age range, account details and passwords.
        </span>
        <br />

        <span>
          Marketing Preferences: Interests, communication preferences, and
          language preferences. Campaign and Feedback Information: Details of
          your applications of our campaigns, inquiries, and feedback
          submissions. Usage Data: Pages visited, clicks, browsing patterns,
          referring webpages, and search queries. Device Information: Device
          type, IP address, operating system, browser type, session frequency,
          and geolocation. Cookies and Tracking Technologies: Data collected
          using cookies, beacons, and similar technologies. Further information
          on cookies is set out below.
        </span>
        <br />

        <span className="font-extrabold text-lg mb-2">
          How Asgard handles the collected information:
        </span>

        <br />

        <ol className="list-decimal list-inside">
          <li>
            <strong>Service Provision</strong>
            <ul className="list-disc list-inside ml-5">
              <li>
                To process applications, respond to inquiries, and manage
                customer accounts.
              </li>
              <li>
                To provide and enhance our Website’s features and functionality.
              </li>
              <li>
                To facilitate promotional activities, events, and prize
                distributions.
              </li>
            </ul>
          </li>
          <br />
          <li>
            <strong>Personalization and Marketing</strong>
            <ul className="list-disc list-inside ml-5">
              <li>
                To tailor content, offers, and services to your preferences.
              </li>
              <li>
                To send you promotional materials, updates, and newsletters
                through email, SMS, postal mail, and targeted online
                advertisements (subject to your consent).
              </li>
              <li>
                To use location-based services to suggest nearby charging
                stations or deliver location-specific notifications.
              </li>
            </ul>
          </li>
        </ol>

        <br />
        <span>
          To share your information with third-party partners for joint
          campaigns or direct marketing purposes (subject to your explicit
          consent).
        </span>
        <br />

        <ol className="list-decimal list-inside">
          <li>
            <strong>Business Operations</strong>
            <ul className="list-disc list-inside ml-5">
              <li>
                To conduct analytics, research, and audits for improving our
                services.
              </li>
              <li>
                To ensure security, prevent fraud, and address technical issues.
              </li>
              <li>
                To maintain internal records and comply with legal obligations.
              </li>
            </ul>
          </li>
        </ol>

        <br />
        <span className="font-extrabold text-lg mb-2">
          We will keep the personal data we hold confidential but may provide
          your personal data to:
        </span>
        <span>
          - Affiliates and Partners
          <br />
          - Government or legal authorities, if required for compliance with
          legal obligations.
          <br />
          - Third-party vendors for IT support, application processing,
          marketing, and analytics.
          <br />- Authorized contractors for event management, prize
          distributions, or promotional activities.
        </span>

        <br />
        <span className="font-extrabold text-lg mb-2">
          Cookies and Tracking Technologies:
        </span>
        <span>
          Cookies are small, sometimes encrypted text files that are stored on
          computer hard drives by websites that you visit. We use cookies and
          tracking technologies to enhance your experience and analyze site
          usage. Cookies do not contain any software programs. There are two
          general types of cookies, session cookies and persistent cookies.
          Session cookies are only used during a session online and will be
          deleted once you leave a website. Persistent cookies have a longer
          life and will be retained by the website and used each time you visit
          a website. Both session and persistent cookies can be deleted by you
          at any time through your browser settings and in any event, will not
          be kept longer than necessary. You can manage your cookie preferences
          through your browser settings.
        </span>
        <br />
        <span className="font-extrabold text-lg mb-2">Data Security:</span>
        <span>
          We take reasonable measures to protect your personal data from
          unauthorized access and misuse. Security measures include encryption,
          access controls and regular system updates. While we strive to protect
          your information, no system can guarantee absolute security.
        </span>
        <br />
        <span className="font-extrabold text-lg mb-2">
          Hyperlinks or Third-Party Links
        </span>
        <span>
          The Site may, from time to time, contain links to external sites
          operated by third parties for your convenience. We have no control of
          and are not responsible for these third party sites or the content
          thereof. Once you leave the Site, we cannot be responsible for the
          protection and privacy of any information which you provide to these
          third party sites. You should exercise caution and look at the privacy
          statement for the website you visit.
        </span>
        <br />
        <span className="font-extrabold text-lg mb-2">Updates or Changes:</span>
        <span>
          We may update this Privacy Policy periodically to reflect changes in
          law, technology, or business operations. We encourage you to review
          this page regularly for the latest updates.
        </span>
        <br />
        <span className="font-extrabold text-lg mb-2">
          Data Privacy Rights:
        </span>
        <span>
          By using our service, making an application or visiting our Site, you
          acknowledge the collection and use of your personal data by us as
          outlined in this Statement. If you do not agree with the use of your
          personal data as set out in this Statement, or the Terms of Use,
          please do not use this Site.
        </span>
        <br />

        <span className="font-extrabold text-lg mb-2">You have the right:</span>
        <ul className="list-decimal list-inside ml-5">
          <li>
            To check whether we hold personal data about you and to access such
            data.
          </li>
          <li>To require us to correct inaccurate data.</li>
          <li>
            To ascertain our policies and practices in relation to personal data
            and the kind of personal data held by us.
          </li>
          <li>
            To request access to and rectification or erasure of your personal
            data.
          </li>
          <li>
            To obtain restriction of processing or to object to processing of
            your personal data.
          </li>
          <li>To the right to data portability.</li>
          <li>
            You have the right to lodge a complaint about the processing of your
            personal data with your local data protection authority.
          </li>
        </ul>
        <br />
        <span className="font-extrabold text-lg mb-2">
          Hyperlinks or Third-Party Links
        </span>
        <span>
          The Site may, from time to time, contain links to external sites
          operated by third parties for your convenience. We have no control of
          and are not responsible for these third party sites or the content
          thereof. Once you leave the Site, we cannot be responsible for the
          protection and privacy of any information which you provide to these
          third party sites. You should exercise caution and look at the privacy
          statement for the website you visit.
        </span>
        <br />
        <span className="text-sm">
          If you wish to exercise one of the above mentioned rights, cease
          marketing communications, raise questions or complaints, and/ or
          request erasure of your personal data, please contact us at:
        </span>
        <br />
        <div className="flex flex-col text-[10px]">
          <span className="font-bold">Data Privacy Officer:</span>
          <span>Asgard Group Limited</span>
          <span>
            UNIT 405 , Sunbeam Centre , No.27 Shing Yip Street , Kwun Tong , KLN
          </span>
          <a href="mailto:hello@asgardev.com.hk">
            Email: hello@asgardev.com.hk
          </a>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default Privacy;
